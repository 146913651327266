import 'react-phone-number-input/style.css'
import './ActivateFreeTrialPage.css'

import { cleanlabColors } from '@assets/styles/CleanlabColors'
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Tag,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import Link from '@components/link/Link'
import ValidationError from '@components/validationError/ValidationError'
import { useAuth } from '@hooks/useAuth'
import { useEventTracking } from '@hooks/useEventTracking'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { GetMyselfRes, queryKeys } from '@services/user/constants'
import { useActivateAccountMutation } from '@services/user/mutations'
import { useMyself } from '@services/user/queries'
import { IS_VPC_ENVIRONMENT } from '@utils/environmentVariables'
import { usePostHog } from 'posthog-js/react'
import { startTransition, useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import PhoneInput from 'react-phone-number-input'
import { useQueryClient } from 'react-query'

import { ActivateFormState } from './ActivateFreeTrialPage.types'
import ActivationMessage from './activationMessage/ActivationMessage'
import PhoneFormField from './phoneFormField/PhoneFormField'

const ActivateFreeTrialPage = () => {
  const { email, accountActivated } = useMyself()
  const posthog = usePostHog()
  const { user } = useAuth()
  const textStrong = useColorModeValue('neutral.900', 'neutralDarkMode.900')
  const textFaint = useColorModeValue('neutral.700', 'neutralDarkMode.700')
  const surface = useColorModeValue('neutral.50', 'neutralDarkMode.50')
  const neutral_300 = useColorModeValue('neutral.300', 'neutralDarkMode.300')
  const blue_700 = useColorModeValue('blue.700', 'blueDarkMode.700')
  const blue_600 = useColorModeValue('blue.600', 'blueDarkMode.600')
  const red_700 = useColorModeValue('red.700', 'redDarkMode.700')
  const invalidBoxShadow = useColorModeValue(
    `0px 0px 0px 2px ${cleanlabColors.red[500]}`,
    `0px 0px 0px 2px ${cleanlabColors.redDarkMode[500]}`
  )
  const countryClassName = useColorModeValue('country-select', 'country-select-dark')

  const { trackEvent, setUserProperties } = useEventTracking()

  const [isError, setIsError] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)

  const queryClient = useQueryClient()

  const { mutate: activateAccount, isLoading } = useActivateAccountMutation({
    onError: () => {
      setIsError(true)
    },
    onSuccess: (res: GetMyselfRes) => {
      const { id, name, email } = res
      setUserProperties(id, name, email)
      trackEvent(MixpanelEvents.activateUserAccount)
      queryClient.invalidateQueries(queryKeys.users.myself())
      startTransition(() => {
        setFormSubmitted(true)
        localStorage.removeItem('signup_origin')
      })
    },
  })

  useEffect(() => {
    // VPC user should never see this page, but let's check to be safe
    if (!IS_VPC_ENVIRONMENT) {
      posthog.capture('activate_free_trial_page_viewed')
    }
  }, [posthog])

  const invalidStyles = {
    borderColor: red_700,
    boxShadow: invalidBoxShadow,
  }

  const {
    handleSubmit,
    control,
    trigger,
    formState: { errors },
  } = useForm<ActivateFormState>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: user?.email ?? email,
      phoneNumber: '',
      signupAccountType: '',
      signupDiscoverySource: '',
      termsAndConditions: false,
    },
  })

  const onSubmit = useCallback(
    (values: ActivateFormState) => {
      const signupOrigin = localStorage.getItem('signup_origin')
      activateAccount({ ...values, signupOrigin: signupOrigin ?? '' })
    },
    [activateAccount]
  )

  return (
    <Container px="10%" mt="32px">
      <Text fontSize="24px" fontWeight="600" lineHeight="32px" color={textStrong}>
        Activate your free trial
      </Text>
      <Text pt="8px" fontSize="18px" lineHeight="28px" color={textFaint}>
        You are almost there! Please confirm your email address to finish the activation process.
      </Text>

      {!isError && !formSubmitted && !accountActivated ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack alignItems="flex-start" mt="20px" spacing="16px">
            <HStack w="100%" spacing="16px">
              <Box w="100%" pb={!errors.firstName && errors.lastName ? '44px' : '0px'}>
                <FormLabel color={textStrong} fontWeight={500} lineHeight="21px">
                  First name*
                </FormLabel>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{
                    required: 'Required',
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl isInvalid={!!error}>
                      <VStack alignItems="flex-start" gap="12px">
                        <Input
                          {...field}
                          maxLength={32}
                          variant="form"
                          placeholder="Enter your first name"
                          _invalid={invalidStyles}
                        />
                        {error && <ValidationError />}
                      </VStack>
                    </FormControl>
                  )}
                />
              </Box>
              <Box w="100%" pb={errors.firstName && !errors.lastName ? '44px' : '0px'}>
                <FormLabel color={textStrong} fontWeight={500} lineHeight="21px">
                  Last name*
                </FormLabel>
                <Controller
                  name="lastName"
                  control={control}
                  rules={{
                    required: 'Required',
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl isInvalid={!!error}>
                      <VStack alignItems="flex-start" gap="12px">
                        <Input
                          {...field}
                          maxLength={32}
                          variant="form"
                          placeholder="Enter your last name"
                          _invalid={invalidStyles}
                        />
                        {error && <ValidationError />}
                      </VStack>
                    </FormControl>
                  )}
                />
              </Box>
            </HStack>
            <Box w="100%">
              <Controller
                name="email"
                control={control}
                defaultValue={user?.email ?? email}
                render={({ fieldState: { error } }) => (
                  <FormControl isInvalid={!!error}>
                    <FormLabel color={textStrong} fontWeight={500} lineHeight="21px">
                      Work or university email
                    </FormLabel>
                    <Input
                      variant="disabled"
                      disabled={true}
                      key={user?.email ?? email}
                      maxLength={32}
                      value={user?.email ?? email}
                    />
                  </FormControl>
                )}
              />
            </Box>
            <Box w="100%">
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl isRequired={false} isInvalid={!!error}>
                    <FormLabel color={textStrong} fontWeight={500} lineHeight="21px">
                      <HStack>
                        <Text>Phone</Text>
                        <Tag
                          color={textFaint}
                          bg={surface}
                          borderRadius="8px"
                          border="1px solid"
                          borderColor={neutral_300}
                          fontSize="11px"
                        >
                          Optional
                        </Tag>
                      </HStack>
                    </FormLabel>
                    <PhoneInput
                      className={countryClassName}
                      international
                      defaultCountry="US"
                      countrySelectProps={{ unicodeFlags: true, tabIndex: 0 }}
                      placeholder="+1 (123) 345-6789"
                      {...field}
                      inputComponent={PhoneFormField}
                      input
                    />
                  </FormControl>
                )}
              />
            </Box>
            <Box w="100%">
              <Controller
                name="signupDiscoverySource"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl isRequired={false} isInvalid={!!error}>
                    <FormLabel color={textStrong} fontWeight={500} lineHeight="21px">
                      <HStack>
                        <Text>How did you hear about Cleanlab?</Text>
                        <Tag
                          color={textFaint}
                          bg={surface}
                          borderRadius="8px"
                          border="1px solid"
                          borderColor={neutral_300}
                          fontSize="11px"
                        >
                          Optional
                        </Tag>
                      </HStack>
                    </FormLabel>
                    <VStack alignItems="flex-start" gap="12px">
                      <Input
                        {...field}
                        maxLength={200}
                        variant="form"
                        placeholder="Enter where you heard about Cleanlab"
                      />
                      {error && <ValidationError />}
                    </VStack>
                  </FormControl>
                )}
              />
            </Box>
          </VStack>
          <VStack alignItems="flex-start" pt="24px" spacing="24px">
            <Controller
              name="termsAndConditions"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl isInvalid={!!error}>
                  <VStack alignItems="flex-start" gap="12px">
                    <HStack align="flex-start">
                      <Checkbox
                        color={blue_600}
                        borderColor={blue_700}
                        isChecked={value}
                        onChange={(evt) => {
                          trigger()
                          onChange(evt)
                        }}
                        mt="0.25rem"
                        _checked={{
                          '& .chakra-checkbox__control': {
                            background: blue_600,
                            borderColor: blue_700,
                          },
                        }}
                      />
                      <Text fontSize="sm" fontWeight={500}>
                        I agree to the{' '}
                        <Link
                          textDecoration="underline"
                          isExternal
                          href="https://cleanlab.ai/terms/"
                        >
                          terms of service
                        </Link>
                        .*
                      </Text>
                    </HStack>
                    {error && <ValidationError />}
                  </VStack>
                </FormControl>
              )}
            />
            <Button
              className="form__submit-button"
              isLoading={isLoading}
              type="submit"
              aria-label="submit"
              variant="primary"
            >
              <span>Submit</span>
            </Button>
          </VStack>
        </form>
      ) : (
        <>
          <ActivationMessage
            email={user?.email ?? email}
            isSuccess={(formSubmitted && !isError) || accountActivated}
          />
          {formSubmitted && !isError && (
            <Text mt="12px" fontSize="sm" lineHeight="21px">
              Still can't find the email?{' '}
              <Link textDecoration="underline" href="mailto:support@cleanlab.ai" isExternal>
                Contact us
              </Link>{' '}
              for support.
            </Text>
          )}
        </>
      )}
    </Container>
  )
}

export default ActivateFreeTrialPage
