import { datadogRum } from '@datadog/browser-rum'
import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import {
  GetUserNotificationRes,
  queryKeys,
  UserNotificationData,
} from '@services/userNotification/constants'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import axios, { AxiosError } from 'axios'
import { useQuery } from 'react-query'

const apiVersion = 'v1'
const axiosClient = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/${apiVersion}/user_notifications`,
  withCredentials: true,
})

export const useCurrentNotification = (isAuthenticated = true): UserNotificationData => {
  const { data } = useQuery<GetUserNotificationRes>({
    queryKey: queryKeys.notifications.all(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await axiosClient.get('/current', createAuthHeaders(accessToken))
      return res.data
    },
    meta: {
      error: 'Failed to fetch current user notification.',
      suppress: true,
      onError: (err: AxiosError) =>
        datadogRum.addError(err, {
          message: 'Error fetching current user notification for banner',
        }),
    },
    enabled: !!isAuthenticated,
  })
  return { hasSeen: data?.has_seen ?? true, message: data?.message ?? '' }
}
