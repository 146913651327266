import './index.css'

import { ColorModeScript } from '@chakra-ui/react'
import { datadogRum } from '@datadog/browser-rum'
import {
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  IS_PROD_ENV,
  IS_VPC_ENVIRONMENT,
} from '@utils/environmentVariables'
import React from 'react'
import { createRoot } from 'react-dom/client'

import App from './App'
import reportWebVitals from './reportWebVitals'

// Performance and Exception Monitoring with Datadog Browser RUM
IS_PROD_ENV &&
  !IS_VPC_ENVIRONMENT &&
  datadogRum.init({
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'cleanlab-studio-frontend',
    env: 'production',
    version: '0.1.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    defaultPrivacyLevel: 'mask-user-input',
    beforeSend: (event) => {
      if (event.type === 'error' && event.error?.source === 'network') {
        const status = (event.error as any).response?.status
        if (status >= 400 && status < 600) {
          const url = (event.error as any).response?.url || ''
          event.error.name = `${status} - ${url}`
          event.error.dd_fingerprint = `${status} - ${new URL(url).pathname}`
        }
      }
      return true
    },
  })

const container = document.getElementById('root')
// eslint-disable-next-line
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <ColorModeScript />
    <App />
  </React.StrictMode>
)

// Performance Monitoring
reportWebVitals()
